import { chakraNextTheme } from '@47ng/chakra-next'
import { extendTheme, theme as chakraBaseTheme } from '@chakra-ui/react'
import '@fontsource/source-code-pro'
import '@fontsource/source-sans-pro'
import '@fontsource/source-sans-pro/600.css' // semibold
import { bodyDark, bodyLight } from './colors'

export const theme = extendTheme({
  styles: {
    global: ({ colorMode }: { colorMode: 'light' | 'dark' }) => ({
      'html, body, #__next': {
        background: colorMode === 'light' ? bodyLight : bodyDark,
        MozOsxFontSmoothing: 'unset'
      }
    })
  },
  semanticTokens: {
    ...chakraNextTheme.semanticTokens,
    'text.contrasted': {
      default: 'gray.800',
      _dark: 'gray.200'
    }
  },
  colors: {
    gray: {
      1000: '#121721'
    },
    gray900Alpha: {
      50: 'rgba(23, 25, 35, 0.04)',
      100: 'rgba(23, 25, 35, 0.06)',
      200: 'rgba(23, 25, 35, 0.08)',
      300: 'rgba(23, 25, 35, 0.16)',
      400: 'rgba(23, 25, 35, 0.24)',
      500: 'rgba(23, 25, 35, 0.36)',
      600: 'rgba(23, 25, 35, 0.48)',
      700: 'rgba(23, 25, 35, 0.64)',
      800: 'rgba(23, 25, 35, 0.80)',
      900: 'rgba(23, 25, 35, 0.92)'
    },
    accent: chakraBaseTheme.colors.blue
  },
  fonts: {
    body: `Source Sans Pro,${chakraBaseTheme.fonts.body}`,
    mono: `Source Code Pro,${chakraBaseTheme.fonts.mono}`
  },
  textStyles: {
    h1: {
      fontSize: { base: '3xl', md: '4xl' },
      fontWeight: 'bold'
    }
  }
})
