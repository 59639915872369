import Script from 'next/script'

export const Dogfood: React.FC = () => {
  const projectID = process.env.NEXT_PUBLIC_CHIFFRE_PROJECT_ID
  const publicKey = process.env.NEXT_PUBLIC_CHIFFRE_PUBLIC_KEY
  const enabled = Boolean(projectID) && Boolean(publicKey)

  if (!enabled) {
    return null
  }

  return (
    <Script
      id="chiffre:analytics"
      src="/analytics.js"
      async
      strategy="afterInteractive"
      data-chiffre-project-id={projectID}
      data-chiffre-public-key={publicKey}
      data-chiffre-server-url=""
      data-chiffre-ignore-paths={['/dashboard', '/projects/']}
    />
  )
}
