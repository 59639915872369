import {
  CaptureConsole,
  ExtraErrorData,
  Offline as OfflineIntegration
} from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

export function getSentryEnvironment(releaseTag?: string) {
  const ref = releaseTag?.split('@')[0] ?? 'development'
  if (ref === 'prod') {
    return 'production'
  }
  return ref
}

export function setupSentry() {
  const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN
  if (!dsn) {
    return
  }
  Sentry.init({
    dsn,
    environment: getSentryEnvironment(process.env.NEXT_PUBLIC_RELEASE_TAG),
    release: process.env.NEXT_PUBLIC_RELEASE_TAG,
    integrations: baseIntegrations => [
      ...baseIntegrations,
      new Integrations.BrowserTracing(),
      new ExtraErrorData(),
      new CaptureConsole({
        levels: ['warn', 'error']
      }),
      new OfflineIntegration()
    ],
    tracesSampleRate: 1.0
  })
}
