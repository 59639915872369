import { useColorModeValue, useToken } from '@chakra-ui/react'

export const bodyLight = 'gray.100'
export const bodyDark = 'gray.1000'

export function useBodyColor() {
  return useColorModeValue(bodyLight, bodyDark)
}

export function useColorModeToken<T extends string | string[]>(
  light: T,
  dark: T
): T extends string[] ? string[] : string {
  return useToken('colors', useColorModeValue(light, dark))
}
