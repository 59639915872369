import { ChakraProvider } from '@chakra-ui/react'
import { Dogfood } from 'client/components/dogfood'
import { Favicons } from 'client/components/head/favicons'
import { setupSentry } from 'client/services/sentry'
import seo from 'modules/seo.json'
import { theme } from 'modules/ui/theme'
import { DefaultSeo } from 'next-seo'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

setupSentry()

const DownlinkController = dynamic<any>(
  () =>
    import(
      /* webpackChunkName: "downlink.controller" */
      'client/workers/downlink.controller'
    ).then(module => module.DownlinkController),
  {
    ssr: false
  }
)

const Devtools = dynamic<any>(
  () => {
    const enableDevtools =
      process.env.NODE_ENV === 'development' ||
      window.localStorage.getItem('chiffre:devtools') === 'true'
    return enableDevtools
      ? import(
          /* webpackChunkName: "devtools" */
          'client/components/devtools/devtools'
        ).then(module => module.Devtools)
      : Promise.resolve(() => null)
  },
  {
    ssr: false
  }
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60_000, // 1 minute
      cacheTime: Infinity,
      keepPreviousData: true,
      notifyOnChangeProps: 'tracked'
    }
  }
})

export const AppCore: React.FC = ({ children }) => {
  return (
    <>
      <DefaultSeo {...seo} />
      <Favicons />
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          {children}
          <Devtools />
          <DownlinkController />
        </ChakraProvider>
      </QueryClientProvider>
      <Dogfood />
    </>
  )
}

export default function App({
  Component,
  pageProps,
  err
}: AppProps & { err?: any }) {
  // Workaround for https://github.com/vercel/next.js/issues/8592
  return <AppCore>{<Component {...pageProps} err={err} />}</AppCore>
}
